import React from 'react'
import Modal from 'react-modal'
import header from './imgs/HMMMM rainbow.png'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '100 !important',
    position              : 'fixed',
    padding               : '.5em 2.5em 1em 2.5em',
    alignItems            : 'center',
    display               : 'flex',
    flexDirection         : 'column',
    textAlign             : 'center'
  }
};

const Subscribe = (props) => {
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal(){
    setIsOpen(false);
  }
  return (
    <div class="subscribe" style={{width: "fit-content"}}>
      <div onClick={openModal} style={{color: props.color, fontSize: "1.3em", fontWeight: "500", fontStyle: "italic", cursor: "pointer"}}>
        subscribe
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Subscribe Modal"
      >

        <img alt="headline" className='subscribeImg' src={header} />
        {/* <h4 style={{color: "black", padding: ".2em 0", marginBottom: "0"}}>Subscribe to receive monthly zine updates!</h4> */}
        {/* <form style={{display: "flex", padding: ".5em 0", zIndex: "1"}} action="https://huntysdoodles.us1.list-manage.com/subscribe/post?u=f18080eb1531aac20292a36fa&amp;id=8aec1f3aff" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <div id="mc_embeded_signup_scroll">
          <input style={{zIndex: "10000"}} type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required />
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div class="mc-field-group input-group" style={{display: "none"}}>
            <strong>hmmmm zine </strong>
            <ul><li><input type="checkbox" value="1" name="group[381153][1]" id="mce-group[381153]-381153-0" checked /><label for="mce-group[381153]-381153-0">subscribers</label></li></ul>
          </div>
          <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_f18080eb1531aac20292a36fa_8aec1f3aff" tabindex="-1" /></div>
          <input type="submit" style={{margin: "-.2em 0 0 .5em", height: "2em", color: "white", backgroundColor: "black", border: "none", borderRadius: "0px"}} value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
          </div>
        </form> */}
        {/* OLD GOOGLE FORM
        <iframe style={{overflowY: "hidden"}} title="hmmmm zine subscrption form" src="https://docs.google.com/forms/d/e/1FAIpQLSeHJGxbXRo6r4t2PfZHiThFrOHEZ4Ufr3CJXsDEpTls08hvqQ/viewform?embedded=true" width="370" height="515" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
        {/* NEW SENDINBLUE FORM */}
        <iframe title="hunty's newsletter" width="540" height="490" src="https://96aab065.sibforms.com/serve/MUIEAIaQImNME0iEuAcD-wa_ecTaUxQjfAO7Tn-c0EpY2Kwyd77rHFI2DI9xHvHaccM6xa9YsAltCs5r_5xDsHY5MDdeo6wmlHHnqIxrs0JJmj2Ldb8Yy9gAuKlU114bVUaBzmzrN5JQV9p2uREoV0Wq5OExn4WTMRtjOjGuoPmLXe3U-5uKKJxqK16w_ouYj747kEWO3ZRa_naB" frameborder="0" scrolling="auto" allowfullscreen style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%"}}></iframe>

        <div style={{fontSize: "1em", zIndex: "1000"}}>
          {/* <a href="https://www.patreon.com/huntysdoodles" target="_blank" rel="noreferrer noopener">Click here</a> to get a <i>physical copy</i> in the mail every month!<br/> */}
          <div style={{fontSize: ".7em"}}>(unsubscribe anytime)</div>
          <div onClick={closeModal} style={{cursor: "pointer"}}><i>click here to close</i></div>
        </div>
      </Modal>
    </div>
  )
}
export default Subscribe;