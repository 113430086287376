import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import './App.css'
import logo from './localImages/floralHMMMMlogo.png'
import Subscribe from './Subscribe';
import { Navbar, Nav as NavBoot } from 'react-bootstrap';

export default class Nav extends Component {
  render() {
    return (
      // <div style={{padding: "1em", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      //   <Link to="/"><img alt="logo" style={{width: "300px"}} src="https://gdurl.com/JXyT" /></Link>
      //   <div style={{display: "flex"}}>
      //     <Link style={{color: this.props.color, textDecoration: "none", fontSize: "1.3em", fontWeight: "500", fontStyle: "italic", padding: "1em"}} to="/">issues</Link>
      //     <Subscribe color={this.props.color} />
      //     {/* <Link style={{color: this.props.color, textDecoration: "none", fontSize: "1.3em", fontWeight: "500", fontStyle: "italic"}} to="/">issues</Link> */}
          
      //   </div>
      // </div>
      <Navbar collapseOnSelect bg="white" expand="sm">
        <Link to="/"><img alt="logo" className='logo' src={logo} /></Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <NavBoot className="mr-auto">
          </NavBoot>
          <NavBoot style={{alignItems: "baseline", alignContent: "center"}}>
            <NavBoot.Link style={{color: "rgba(0,0,0,.5)", fontSize: "1.3em", fontWeight: "500", fontStyle: "italic", paddingRight: "1.5em"}} href="/about">about</NavBoot.Link>
            <NavBoot.Link style={{color: "rgba(0,0,0,.5)", fontSize: "1.3em", fontWeight: "500", fontStyle: "italic", paddingRight: "1.5em"}} href="/issues">issues</NavBoot.Link>
            <NavBoot.Link><Subscribe color="rgba(0,0,0,.5)" /></NavBoot.Link>
          </NavBoot>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
