import React, { Component } from 'react'
import cover from '../imgs/zine8/cover.PNG'
import pg1 from '../imgs/zine8/1.PNG'
import pg2 from '../imgs/zine8/2.PNG'
import pg3 from '../imgs/zine8/3.PNG'
import pg4 from '../imgs/zine8/4.PNG'
import pg5 from '../imgs/zine8/5.PNG'
import pg6 from '../imgs/zine8/6.PNG'
import pg7 from '../imgs/zine8/7.PNG'
import pg8 from '../imgs/zine8/8.PNG'
import pg9 from '../imgs/zine8/9.PNG'
import pg10 from '../imgs/zine8/10.PNG'
import pg11 from '../imgs/zine8/11.PNG'
import pg12 from '../imgs/zine8/12.PNG'
import back from '../imgs/zine8/back.PNG'

var pageStyle = {
  width: "25em"
}

export default class Eight extends Component {
  render() {
    return (
      <div>
        <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", backgroundColor: "#fed7ea", background: "white url(https://gdurl.com/zUud) repeat fixed top", backgroundSize: "100vw", overflowX: "hidden"}}>
          {/* <img style={{width: "20em", margin: "3em 0", boxShadow: "-10px 10px #7F8500"}} src={cover} alt="HMMMM 1" /> */}
          <div style={{backgroundColor: "white", paddingBottom: "2em", marginTop: '3em'}}>
            <img style={pageStyle} src={cover} alt="HMMMM 1" />
            <img style={pageStyle} src={pg1} alt="HMMMM 1" />
            <img style={pageStyle} src={pg2} alt="HMMMM 1" />
            <img style={pageStyle} src={pg3} alt="HMMMM 1" />
            <img style={pageStyle} src={pg4} alt="HMMMM 1" />
            <img style={pageStyle} src={pg5} alt="HMMMM 1" />
            <img style={pageStyle} src={pg6} alt="HMMMM 1" />
            <img style={pageStyle} src={pg7} alt="HMMMM 1" />
            <img style={pageStyle} src={pg8} alt="HMMMM 1" />
            <img style={pageStyle} src={pg9} alt="HMMMM 1" />
            <img style={pageStyle} src={pg10} alt="HMMMM 1" />
            <img style={pageStyle} src={pg11} alt="HMMMM 1" />
            <img style={pageStyle} src={pg12} alt="HMMMM 1" />
            <img style={pageStyle} src={back} alt="HMMMM 1" />
          </div>      
        </div>
      </div>
    )
  }
}
