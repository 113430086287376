import React from 'react'

export default function About() {
  return (
    <div style={{height: "100%", width: "100%", padding: "80px 40px", display: "flex", flexFlow: "row wrap", justifyContent: "center", background: "white url(https://gdurl.com/zUud) repeat fixed top", backgroundSize: "100vw"}}>
        <div style={{backgroundColor: "white", padding: "20px"}}>
            <h1 style={{color: "rgba(0,0,0,.5)", fontWeight: "500", fontStyle: "italic", margin: "5px 0 15px 0"}}>about this zine</h1>
            <div>
                This music zine is a reoccurring music zine that I like to create every so often to share releases of music that I'm currently enjoying and want to share with others! I'm also expanding it to include more music from my friends/people in my circles and highlight releases that I think deserve more attention!<br/><br/>

                I started this in 2021 as a monthly zine that I released through Patreon, but it soon became too much to handle every month. I also originally had a focus on "mathy" music, which is why the original title of the zine was "Hunty's Mostly Mathy Monthly Music zine."<br/><br/>
                
                After a break from making issues, I decided to reboot it as a more occasionally-released zine, keeping the HMMMM moniker but shortening it to simply "Hunty's Music Zine", focusing more generally on music that was either underground, in my circles or things that particularly struck my ear and I wanted to talk about.<br/><br/>
                
                I still release the zine in a physical form and if I have any in stock, they will be available for purchase at live events that I attend or on my <a href="https://huntysdoodles.square.site/s/shop" target='_blank' rel="noopener noreferrer">online store</a>. Also hi I'm hunty! Thanks for being here!
            </div>
        </div>
    </div>
  )
}
