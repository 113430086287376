import React, { Component } from 'react'
import cover from '../imgs/2023zine/frontcover.png'
import pg1 from '../imgs/2023zine/ZINE_2023 2.png'
import pg2 from '../imgs/2023zine/ZINE_2023 3.png'
import pg3 from '../imgs/2023zine/ZINE_2023 4.png'
import pg4 from '../imgs/2023zine/ZINE_2023 5.png'
import pg5 from '../imgs/2023zine/ZINE_2023 6.png'
import pg6 from '../imgs/2023zine/ZINE_2023 7.png'
import pg7 from '../imgs/2023zine/ZINE_2023 8.png'
import pg8 from '../imgs/2023zine/ZINE_2023 9.png'
import pg9 from '../imgs/2023zine/ZINE_2023 10.png'
import pg10 from '../imgs/2023zine/ZINE_2023 11.png'
import pg11 from '../imgs/2023zine/ZINE_2023 12.png'
import pg12 from '../imgs/2023zine/ZINE_2023 13.png'
import pg13 from '../imgs/2023zine/ZINE_2023 14.png'
import pg14 from '../imgs/2023zine/ZINE_2023 15.png'
import pg15 from '../imgs/2023zine/ZINE_2023 16.png'
import pg16 from '../imgs/2023zine/ZINE_2023 17.png'
import pg17 from '../imgs/2023zine/ZINE_2023 18.png'
import pg18 from '../imgs/2023zine/ZINE_2023 19.png'
import back from '../imgs/2023zine/backcover.png'


var pageStyle = {
  width: "25em"
}

export default class Eight extends Component {
  render() {
    return (
      <div>
        <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", backgroundColor: "#fed7ea", background: "white url(https://gdurl.com/zUud) repeat fixed top", backgroundSize: "100vw", overflowX: "hidden"}}>
          {/* <img style={{width: "20em", margin: "3em 0", boxShadow: "-10px 10px #7F8500"}} src={front} alt="HMMMM 1" /> */}
          <div style={{backgroundColor: "white", paddingBottom: "2em", marginTop: '3em'}}>
            <img style={pageStyle} src={cover} alt="HMMMM 1" />
            <img style={pageStyle} src={pg1} alt="HMMMM 1" />
            <img style={pageStyle} src={pg2} alt="HMMMM 1" />
            <img style={pageStyle} src={pg3} alt="HMMMM 1" />
            <img style={pageStyle} src={pg4} alt="HMMMM 1" />
            <img style={pageStyle} src={pg5} alt="HMMMM 1" />
            <img style={pageStyle} src={pg6} alt="HMMMM 1" />
            <img style={pageStyle} src={pg7} alt="HMMMM 1" />
            <img style={pageStyle} src={pg8} alt="HMMMM 1" />
            <img style={pageStyle} src={pg9} alt="HMMMM 1" />
            <img style={pageStyle} src={pg10} alt="HMMMM 1" />
            <img style={pageStyle} src={pg11} alt="HMMMM 1" />
            <img style={pageStyle} src={pg12} alt="HMMMM 1" />
            <img style={pageStyle} src={pg13} alt="HMMMM 1" />
            <img style={pageStyle} src={pg14} alt="HMMMM 1" />
            <img style={pageStyle} src={pg15} alt="HMMMM 1" />
            <img style={pageStyle} src={pg16} alt="HMMMM 1" />
            <img style={pageStyle} src={pg17} alt="HMMMM 1" />
            <img style={pageStyle} src={pg18} alt="HMMMM 1" />
            <img style={pageStyle} src={back} alt="HMMMM 1" />
            <br /><a href="https://www.double-dog.com/product/hunty-s-music-zine-2023-edition-hmmmm-issue-9-/31?cs=true&cst=popular" target='_blank' rel="noopener noreferrer">
            <h1 style={{fontWeight: "500", fontStyle: "italic", margin: "10px 0 20px 0"}}>get a physical copy of this zine here!</h1></a><br/>
          </div>     
        </div>
      </div>
    )
  }
}
