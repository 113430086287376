import React, { Component } from 'react'
import cover from '../imgs/zine3/cover.png'
import pg1 from '../imgs/zine3/1.png'
import pg2 from '../imgs/zine3/2.png'
import pg3 from '../imgs/zine3/3.png'
import pg4 from '../imgs/zine3/4.png'
import pg5 from '../imgs/zine3/5.png'
import pg6 from '../imgs/zine3/6.png'
import pg7 from '../imgs/zine3/7.png'
import pg8 from '../imgs/zine3/8.png'
import pg9 from '../imgs/zine3/9.png'
import pg10 from '../imgs/zine3/10.png'
import pg11 from '../imgs/zine3/11.png'
import pg12 from '../imgs/zine3/12.png'
import back from '../imgs/zine3/footer.png'

var pageStyle = {
  width: "25em"
}

export default class Two extends Component {
  render() {
    return (
      <div>
        <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", backgroundColor: "#93EBCA", background: "white url(https://gdurl.com/zUud) repeat fixed top", backgroundSize: "100vw", overflowX: "hidden"}}>
          {/* <img style={{width: "20em", boxShadow: "-10px 10px #857ffe", margin: "3em 0"}} src="https://gdurl.com/7nMz" alt="HMMMM 3" /> */}
          <div style={{backgroundColor: "white", paddingBottom: "2em", marginTop: '3em'}}>
            <img style={pageStyle} src={cover} alt="HMMMM 1" />
            <img style={pageStyle} src={pg1} alt="HMMMM 1" />
            <img style={pageStyle} src={pg2} alt="HMMMM 1" />
            <img style={pageStyle} src={pg3} alt="HMMMM 1" />
            <img style={pageStyle} src={pg4} alt="HMMMM 1" />
            <img style={pageStyle} src={pg5} alt="HMMMM 1" />
            <img style={pageStyle} src={pg6} alt="HMMMM 1" />
            <img style={pageStyle} src={pg7} alt="HMMMM 1" />
            <img style={pageStyle} src={pg8} alt="HMMMM 1" />
            <img style={pageStyle} src={pg9} alt="HMMMM 1" />
            <img style={pageStyle} src={pg10} alt="HMMMM 1" />
            <img style={pageStyle} src={pg11} alt="HMMMM 1" />
            <img style={pageStyle} src={pg12} alt="HMMMM 1" />
            <img style={pageStyle} src={back} alt="HMMMM 1" />
          </div>
        </div>
      </div>
    )
  }
}
