import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import issue9 from './imgs/2023zine/frontcover.png'
import issue8 from './imgs/zine8/cover.PNG'
import issue7 from './imgs/zine7/cover.png'
import issue6 from './imgs/zine6/cover.PNG'
import issue5 from './imgs/zine5/cover.PNG'
import issue4 from './imgs/zine4/cover.png'
import issue3 from './imgs/zine3/cover.png'
import issue2 from './imgs/zine2/cover.png'
import issue1 from './imgs/zine1/cover.png'

export default class Issues extends Component {
  render() {
    return (
      <div style={{backgroundColor: "white", overflowX: "hidden"}}>
        <div style={{color: "rgba(0,0,0,.5)", margin: "15px 0 25px 0"}}>
          <h1 style={{fontWeight: "500", fontStyle: "italic", margin: "0"}}>zine issues</h1>
        </div>
        <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", background: "white url(https://gdurl.com/zUud) repeat fixed top", backgroundSize: "100vw"}}>
          <Link to="/nine">
            <img style={{width: "20em", backgroundColor: "#fed7ea", margin: "2em", boxShadow: "-10px 10px #7F8500"}} src={issue9} alt="HMMMM 9" />
          </Link>
          <Link to="/eight">
            <img style={{width: "20em", backgroundColor: "#fed7ea", margin: "2em", boxShadow: "-10px 10px #7F8500"}} src={issue8} alt="HMMMM 8" />
          </Link>
          <Link to="/seven">
            <img style={{width: "20em", backgroundColor: "#fed7ea", margin: "2em", boxShadow: "-10px 10px #97a8ea"}} src={issue7} alt="HMMMM 7" />
          </Link>
          <Link to="/six">
            <img style={{width: "20em", backgroundColor: "#c266aa", margin: "2em", boxShadow: "-10px 10px #c266aa"}} src={issue6} alt="HMMMM 6" />
          </Link>
          <Link to="/five">
            <img style={{width: "20em", backgroundColor: "#c266aa", margin: "2em", boxShadow: "-10px 10px #c266aa"}} src={issue5} alt="HMMMM 5" />
          </Link>
          <Link to="/four">
            <img style={{width: "20em", backgroundColor: "#FFCE73", margin: "2em", boxShadow: "-10px 10px #F06F5F"}} src={issue4} alt="HMMMM 4" />
          </Link>
          <Link to="/three">
            <img style={{width: "20em", backgroundColor: "#93EBCA", margin: "2em", boxShadow: "-10px 10px #857FFE"}} src={issue3} alt="HMMMM 3" />
          </Link>
          <Link to="/two">
            <img style={{width: "20em", backgroundColor: "#FFFBD8", margin: "2em", boxShadow: "-10px 10px #7F8500"}} src={issue2} alt="HMMMM 2" />
          </Link>
          <Link to="/one">
            <img style={{width: "20em", backgroundColor: "#fed7ea", margin: "2em", boxShadow: "-10px 10px #97a8ea"}} src={issue1} alt="HMMMM 1" />
          </Link>
        </div>
      </div>
    )
  }
}
